import React from "react";
import JSONData from "../../../content/content.json"
import parse from 'html-react-parser';

export default function Footer() {
  return (
    <footer className={'main-footer'}>
      <div className="container">
        <div className="main-footer__top">
          <div className="anbi">
            <h4>{parse(JSONData.footer.anbi.title)}</h4>
            <p>
              {parse(JSONData.footer.anbi.text.full_name)}
              {parse(JSONData.footer.anbi.text.coc)}
              {parse(JSONData.footer.anbi.text.rsin)}
            </p>

            <img className={'main-footer__top__logo'} src={'/limelight-logo.png'} alt={'Limelight Logo'}/>
          </div>

          <div className="main-footer__right">

            <div className="main-footer__right__contact">
              <h4>{parse(JSONData.footer.contact.title)}</h4>

              <p>
                {parse(JSONData.footer.contact.postalAddress.title)}{parse(JSONData.footer.contact.postalAddress.text)}
                <br />
                <a className="liam" href="mailto:enquiries@limelight.foundation">enquiries@limelight.foundation</a><br />(<a href={'/0xD45D287B.asc'} target={'_blank'} rel={'noreferrer'}>{parse(JSONData.footer.contact.GPGPublicKey)}</a>)
              </p>
            </div>

            <div className="main-footer__right__downloads">
              <h4>{parse(JSONData.footer.downloads.title)}</h4>

              <div className="main-footer__right__text-image-wrapper">
                <p>
                  <a href='pdfs/limelight-annual-report-2023.pdf' target={'_blank'} rel={'noreferrer'}>{parse(JSONData.shared.annualReport2023)}</a><br />
                  <a href='pdfs/limelight-annual-report-2022.pdf' target={'_blank'} rel={'noreferrer'}>{parse(JSONData.shared.annualReport2022)}</a><br />
                  <a href='pdfs/limelight-annual-report-2021.pdf' target={'_blank'} rel={'noreferrer'}>{parse(JSONData.shared.annualReport2021)}</a><br />
                  <a href='pdfs/limelight-anbi-form-2023.pdf' target={'_blank'} rel={'noreferrer'}>{parse(JSONData.footer.downloads.anbiForm2023)}</a><br />
                  <a href='pdfs/limelight-policy-plan-foundation-2021_2024.pdf' target={'_blank'} rel={'noreferrer'}>{parse(JSONData.footer.downloads.policyPlan)}</a><br/>
                  <a href='pdfs/llf-privacy-statement-december-2023.pdf' target={'_blank'} rel={'noreferrer'}>{parse(JSONData.footer.downloads.privacyStatement)}</a>
                </p>

                <img className={'logo'} src={'/limelight-logo.png'} alt={'Limelight Logo'}/>
              </div>
            </div>

          </div>
        </div>

        <div className="main-footer__copyright">
          <p>&copy;&nbsp;{new Date().getFullYear()}&nbsp;{parse(JSONData.footer.copyright)}</p>
        </div>

      </div>
    </footer>
  )
}
